<template>
  <div>
    <primary-modal ref="createProject" modal-id="create_project" title="Create Project">
      <div class="create-project p-3">
        <b-form ref="createProjectForm" @submit.prevent="onSubmit">
          <b-form-group label="Project Name" label-for="project_name">
            <b-form-input id="project_name" v-model="form.name" class="create-project__input"
                          type="text"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Project Description" label-for="project_description">
            <b-form-textarea id="project_description" v-model="form.description"
                             class="create-project__input" type="text"
            ></b-form-textarea>
          </b-form-group>

          <b-form-group label="Start Date" label-for="start_date" class="mt-2">
            <b-form-input id="start_date" v-model="form.start_date" class="create-project__input--select"
                          type="date"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Proposed End Date" label-for="proposed_end">
            <b-form-input id="end_date" v-model="form.end_date" class="create-project__input"
                          type="date"
            ></b-form-input>
          </b-form-group>

          <b-button block type="submit" variant="primary" class="mt-5">
            {{ processing ? "" : "Submit" }}
            <RingLoader v-if="processing" />
          </b-button>
        </b-form>
      </div>
    </primary-modal>
  </div>
</template>
  
<script>
import PrimaryModal from "@/components/PrimaryModal.vue";
import RingLoader from "@/components/loader/RingLoader";

export default {
    components: {
        PrimaryModal,
        RingLoader
    },
    props: {
        hiringType: {
            type: Array,
            default: () => []
        },
        processing: {
          type: Boolean,
          default: false
        }
    },
    data() {
      return {
        form: {
          name: '',
          description: '',
          start_date: '',
          end_date: ''
        },
      }
    },
    methods: {
      onSubmit() {
        this.$emit('createProject', this.form)
      },
      selectHiringType(value) {
        this.form.hiring_type = value
      },
      skillSelected(value) {
        this.form.skill = value
      },
    },
};
</script>
  
<style lang="scss" scoped>

</style>
  