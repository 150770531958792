<!-- eslint-disable vue/html-indent -->
<template>
  <div class="projects dashboard-content mt-5">
    <div class="projects__container pt-5">
      <div class="projects__actions d-lg-flex justify-content-end">
        <div class="projects__search m-width-300 mr-2">
          <SearchBox v-model="searchedProjects" bg-color="#D6DDE4" :rounded="5"
            placeholder="Search projects by name, date, status..." @input="runSearchData" />
        </div>
        <b-button variant="primary" class="projects__request-talent btn py-2 py-lg-0 mt-2 mt-lg-0 mr-2"
          @click="showCreateProjectModal">
          Create Project
        </b-button>
      </div>

      <div class="projects__table mt-3 mb-4 pb-4">
       <div v-if="projects.length">
        <b-table striped hover responsive :fields="fields" :items="filteredProjects ? filteredProjects.reverse() : projects" :per-page="perPage" :current-page="currentPage" :sort-desc.sync="sortDesc">
          <template #head(status_readable)>
            <span>
              Status
            </span>
          </template>
          <template #cell(status_readable)="data">
            <span :class="getVariant(data.item.status_readable)">{{
              data.item.status_readable | capitalize
            }}</span>
          </template>

          <template #cell(info)="data">
            <span role="button" @click="moreInfo(data.item.id)">
              <EyeIcon />
            </span>
          </template>
        </b-table>

        <div class="m-pagination overflow-auto d-flex p-4 justify-content-between align-items-center">
          <span class="flex-grow-1">
            Showing page {{ currentPage }} of {{ pages }}
          </span>
          <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="totalRows" align="right" pills>
          </b-pagination>
        </div>
       </div>

        <div v-else class="py-5 d-flex flex-colum justify-content-center ">
        <!-- <div class="">
          <EmptyIcon />
        </div> -->
        <p class="mt-3">No Employees available</p>
      </div>
      </div>
    </div>

    <CreateProjectModal :hiring-type="hiring_type" :skill="slides" :processing="processing" @createProject="createProject" />
  </div>
</template>

<script>
import SearchBox from "../../components/SearchBox.vue";
import EyeIcon from "../../components/svg/EyeIcon.vue";
import CreateProjectModal from "@/components/projects/CreateProjectModal.vue"
import experienceData from '@/data/experience.json';
import skillData from '@/data/skills.json';
import { mapActions, mapState } from "vuex";

export default {
  components: {
    SearchBox,
    EyeIcon,
    CreateProjectModal
  },
  data() {
    return {
      perPage: 7,
      currentPage: 1,
      sortDesc: true,
      filterValue: "week",
      experience: experienceData.experience,
      slides: skillData.slides,
      searchedProjects: '',
      filteredProjects: this.projects,
      processing: false,
      status: {
        1: 'Pending',
        2: 'Active',
        3: 'Paused',
        4: 'Ended'
      },
      statusVariant: {
        1: 'text-warning',
        2: 'text-primary',
        3: 'text-secondary',
        4: 'text-success'
      },
      payload: {},
      filters: [
        {
          name: "This week",
          value: "week",
        },
        {
          name: "This Month",
          value: "month",
        },
        {
          name: "This Year",
          value: "year",
        },
      ],
      fields: [
        {
          key: "name"
        },
        {
          key: "start_date"
        },
        {
          key: "end_date"
        },
        {
          key: "no_of_employees"
        },
        {
          key: "status_readable"
        },
        {
          key: "info"
        }
      ],
      hiring_type: [
        "Full time",
        "Part time"
      ]
    }
  },
  computed: {
    totalRows() {
      return  this.projects?.length
    },
    pages() {
      return  Math.ceil(this.projects?.length/this.perPage)
    },
    ...mapState({
      projects: (state) => state.projects.projects
    }),
  },
  created() {
    this.fetchProjects();
  },
  methods: {
    ...mapActions({
      fetchProjects: "projects/fetchProjects",
    }),
    getVariant(status_readable) {
      return this.statusVariant[status_readable];
    },
    moreInfo(id) {
      this.$router.push(`/dashboard/projects/${id}`)
    },
    showCreateProjectModal() {
      this.$bvModal.show('create_project')
    },
    createProject(value) {
      this.processing = true
      this.payload = value
      this.$store
        .dispatch("projects/createProject", this.payload)
        .then(() => {
          this.$bvModal.hide('create_project')
          this.fetchProjects()
        })
        .catch((err) => {
          let data = err?.response;
          if (data?.status === 422) {
            this.errors = this.getErrors(data.data.errors);
          }
        })
        .finally(() => {
          this.processing = false
        })
    },
    requestTalent(form) {
      this.payload = form
    },
    runSearchData() {
      if(this.searchedProjects !== '') {
        this.filteredProjects = this.projects.filter((project) => project.name.toLowerCase().includes(this.searchedProjects.toLowerCase()))
      }
      else {
        this.filteredProjects = this.projects
      }
      
    },
  },
}
</script>

<style lang="scss" scoped>
.projects {
  &__table {
    background-color: $white;
    border-radius: $size-10;
  }

  // &__search, &__request-talent {
  //   justify-self: end;
  //   width: 75%;
  //   max-width: 20em;
  // }
}
</style>